import React from 'react';
import { connect } from 'react-redux';
import { clearEarthworks, addEarthworks, removeEarthwork, changeEarthwork, addEarthwork } from './Actions';
import { DataField, showNotice, showConfirm, showMessage, TopContractAndSiteSelect, TruckSelect, selectTruck } from 'components';
import { fetch, integerValue, Socket, timer, toETRSTM35FIN, testIsValidRoadInfo, paddedNumber, stateValueParser,
         getRoadData } from '../utils.js';
import { AccuracyFixer, SetBankingName } from './Components';
import ChangeEarthworkView from './Components';
import './Earthwork.css';



const View = props => {
  if (props.view === 0) {
    if (props.earthworks.last() == null || props.lastBankLatitude ||
        props.lastBankingLatitude) {
      return (
        <LoadInput changeState={props.changeState}
                   location_road_number={props.location_road_number}
                   road={props.road} roadPart={props.roadPart}
                   roadDistance={props.roadDistance}
                   onLoad={props.onLoad}
                   mass={props.mass}
                   attentions={props.attentions}
                   loadingLoad={props.loadingLoad}
                   autoGPS={props.autoGPS}
                   dailyCount={props.dailyCount}
                   dailyMass={props.dailyMass}
                   store={props.store}
                  />
      );
    }

    return (
      <BankInput changeState={props.changeState}
              road={props.road} roadPart={props.roadPart}
              roadDistance={props.roadDistance}
              toggleSetBanking={props.toggleSetBanking}
              onBank={props.onBank}
              loadingBank={props.loadingBank}
              autoGPS={props.autoGPS}
              lastLoadPart={props.lastLoadPart}
              lastLoadDistance={props.lastLoadDistance}
              lastMass = {props.lastMass}
              lastTruck = {props.lastTruck}
              dailyCount={props.dailyCount}
              dailyMass={props.dailyMass}
                />
    );
  }
  else  {
    return (
      <div>
        <TrackingInfo changeState={props.changeState} earthworks={props.earthworks}
                      site={props.selectedConstructionSite} dailyMass={props.dailyMass}
                      wholeMass={props.wholeMass}
                      loading={props.loadingEarthworks}
                      removeEarthwork={props.confirmRemoveEarthwork}
                      removeLocalEarthwork={props.confirmRemoveLocalEarthwork}
                      roadNumber={props.location_road_number} roadPart={props.location_road_part}
                      roadPole={props.location_road_pole} accuracy={props.accuracy}
                      time={props.locationTime} changeEarthwork={props.changeEarthwork} />
      </div>
    );
  }
};

const LoadInput = props => {
  let road = props.road;

  if (road == null) {
    road = props.location_road_number;
  }

  return (
    <fieldset>
      <legend>
        <h4>Kuormatiedot</h4>
      </legend>
      <div className='row borders'>
          <div className='column'>
            <label htmlFor='road'>Tie</label>
            <input id='road' type='text' value={road || '-'} readOnly/>
          </div>
          <div className='column'>
            <label htmlFor='roadPart'>Tieosa</label>
            Edellinen: {props.lastPart || '-'}
            <input id='roadPart' type='tel'
                  value={props.roadPart}
                  onChange={props.changeState.bind(this, 'roadPart', 'integer', 0)}
                  readOnly={props.autoGPS}
                  required/>
          </div>
          <div className='column'>
            <label htmlFor='pole'>Paalu</label>
            Edellinen: {props.lastPole || '-'}
            <input id='pole' type='tel'
                  value={props.roadDistance}
                  onChange={props.changeState.bind(this, 'roadDistance', 'integer', 0)}
                  readOnly={props.autoGPS}
                  required/>
            <label className='checkbox'>
              Automattinen GPS
              <input type='checkbox'
                onChange={props.changeState.bind(this, 'autoGPS', 'boolean', true)} 
                checked={props.autoGPS}/>
            </label>
          </div>
        </div>
        <div className='row borders'>
          <div className='column'>
            <label htmlFor='truck'>Rekka</label>
            Päivän kuormat: {props.dailyCount || '-'} kpl
            <TruckSelect required store={props.store} />
          </div>
          <div className='column'>
            <label htmlFor='mass'>Kuorma (Tonnit) </label>
            Päivän: {props.dailyMass || '-'} tonnia
            <input id='mass' type='tel'
                  value={props.mass}
                  onChange={props.changeState.bind(this, 'mass', 'float', 0.0)} required/>
          </div>
        </div>
        <label htmlFor='attentions'>Huomiot</label>
        <input id='attentions' type='text' value={props.attentions || ''}
              onChange={props.changeState.bind(this, 'attentions', 'string', '')} />
        { props.loadingLoad ? <div className='loader float-left'/> :
          <button onClick={props.onLoad}>Lastaa</button>
        }
    </fieldset>
  );
};

const BankInput = props => {
  return (
    <fieldset>
      <legend>
        <h4>Kippaustiedot</h4>
      </legend>
      <div className='row borders'>
        <div className='column'>
          <label htmlFor='loadRoadPart'>Lastaus tieosa</label>
          <input id='loadRoadPart' type='tel'
                value={props.lastLoadPart}
                readOnly />
        </div>
        <div className='column'>
          <label htmlFor='loadRoadDistance'>Lastaus paalu</label>
          <input id='loadRoadDistance' type='tel'
                value={props.lastLoadDistance}
                readOnly />
        </div>
        <div className='column'>
          <label htmlFor='truck'>Rekka</label>
          Päivän kuormat: {props.dailyCount || '-'} kpl
          <input id='mass' type='text'
                  value={props.lastTruck}
                  readOnly />
        </div>
        <div className='column'>
          <label htmlFor='mass'>Kuorma (Tonnit) </label>
          Päivän: {props.dailyMass || '-'} tonnia
          <input id='mass' type='tel'
                value={props.lastMass}
                readOnly />
        </div>
      </div>
      <div className='row borders'>
          <div className='column'>
            <label htmlFor='road'>Tie</label>
            <input id='road' type='text' value={props.road || ''} readOnly/>
          </div>
          <div className='column'>
            <label htmlFor='roadPart'>Tieosa</label>
            Edellinen: {props.lastPart || '-'}
            <input id='roadPart' type='tel'
                  value={props.roadPart}
                  onChange={props.changeState.bind(this, 'roadPart', 'integer', 0)}
                  readOnly={props.autoGPS}
                  required/>
          </div>
          <div className='column'>
            <label htmlFor='pole'>Paalu</label>
            Edellinen: {props.lastDistance || '-'}
            <input id='pole' type='tel'
                  value={props.roadDistance}
                  onChange={props.changeState.bind(this, 'roadDistance', 'integer', 0)}
                  readOnly={props.autoGPS}
                  required/>
            <label className='checkbox'>
              Automattinen GPS
              <input type='checkbox'
                onChange={props.changeState.bind(this, 'autoGPS', 'boolean', true)} 
                checked={props.autoGPS}/>
            </label>
          </div>
        </div>
        <div className='row'>
          <div className='column center'>
            <button onClick={props.toggleSetBanking}>Kippaa läjitys</button>
          </div>
        <div className='column center'>
          { props.loadingBank ?
            <div className='loader float-left'/> : <button onClick={props.onBank}>Kippaa penger</button>
          }
        </div>
      </div>
    </fieldset>
  );
};

const CurrentData = props => {
  return (
    <div>
      Päivän käytetty massa: <strong>{Math.round(props.dailyMass * 100) / 100 || '-'}</strong> tonnia
      <br/>
      Kohteen käytetty massa: <strong>{Math.round(props.wholeMass * 100) / 100 || '-'}</strong> tonnia
    </div>
  );
}

const TrackingInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>Seurantatiedot</h4>
      </legend>
      <FiveLastEarthworks earthworks={props.earthworks} site={props.site}
                        loading={props.loading} removeEarthwork={props.removeEarthwork}
                        removeLocalEarthwork={props.removeLocalEarthwork} changeEarthwork={props.changeEarthwork}/>
      <div className='row'>
        <div className='column'>
          <CurrentLocation roadNumber={props.roadNumber} roadPart={props.roadPart}
                           roadPole={props.roadPole} loading={props.loading}
                           accuracy={props.accuracy} time={props.time}/>
        </div>
        <div className='column'>
          <CurrentData dailyMass={props.dailyMass} wholeMass={props.wholeMass}/>
        </div>
      </div>
    </fieldset>
  );
};

const FiveLastEarthworks = props => {
  if (props.site == null) return null;
  if (props.earthworks.size === 0) return <p>Ei yhtään lisäystä</p>;
  if (props.loading) return <div className="loader"></div>;

  return (
    <div>
      <h4>5 viimeisintä maanajoa</h4>
      { props.earthworks.splice(0, props.earthworks.size - 5).map(earthwork => {
          const date = new Date(earthwork.get('date'));
          const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' +
                       paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes());
          let header = [];

          header.push(<span key={earthwork.get('id')}>
                        {time + ' | ' + earthwork.get('truck').get('register_number') +
                        ' | Lastaus: ' + earthwork.get('road_part') + ' / ' + earthwork.get('road_distance') +
                        ' | ' +
                        (earthwork.get('banking_latitude') ? 'Läjitys' : (earthwork.get('bank_latitude') ? 'Penger' : 'Kippaamaton')) +
                        ' | Massa: ' + earthwork.get('mass')}
                      </span>);

          let loadtime = '-';

          if (earthwork.get('banking_date')) {
            const bankingDate = new Date(earthwork.get('banking_date'));
            loadtime = bankingDate.getDate() + '.' + (bankingDate.getMonth() + 1) + '.' + bankingDate.getFullYear() + ' ' + 
                                paddedNumber(bankingDate.getHours()) + ':' + paddedNumber(bankingDate.getMinutes());
          }
          else {
            const bankDate = new Date(earthwork.get('bank_date'));
            loadtime = bankDate.getDate() + '.' + (bankDate.getMonth() + 1) + '.' + bankDate.getFullYear() + ' ' +
                            paddedNumber(bankDate.getHours()) + ':' + paddedNumber(bankDate.getMinutes());
          }

          let roadInfo = '-';

          if (earthwork.get('banking_road_part')) {
            roadInfo = earthwork.get('banking_road_part') + ' / ' + earthwork.get('banking_road_distance');
          }

          if (earthwork.get('bank_road_part')) {
            roadInfo = earthwork.get('bank_road_part') + ' / ' + earthwork.get('bank_road_distance');
          }

          let data = { 
                      'Huomiot': earthwork.get('attentions') || '-',
                      'Kippaus aika': loadtime,
                      'Kippaus tierekisteri': roadInfo,
                    };

          if (earthwork.get('banking_name')) {
            data['Läjitysalue'] = earthwork.get('banking_name');
          }

          if (earthwork.get('not_saved')) {
            header.push(<span key={earthwork.get('id') + '-not_saved'}>
                          {earthwork.get('not_saved') ? ' (Ei palvelimella)' : ''}
                        </span>);
          }

          return (
            <div className='datafield' key={earthwork.get('id')}>
              <DataField header={header} data={data} class={earthwork.get('not_saved') ? 'yellow' : null}>
                <br/>
                <div className='row center'>
                  <div className='column'>
                    <button onClick={props.changeEarthwork.bind(this, earthwork)}>
                      Muokkaa
                    </button>
                  </div>
                  <div className='column'>
                    <button className='button-outline' onClick={earthwork.get('not_saved') ? 
                                                        props.removeLocalEarthwork.bind(this, earthwork.get('id')) :
                                                        props.removeEarthwork.bind(this, earthwork.get('id'))}>
                      Poista
                    </button>
                  </div>
                </div>
              </DataField>
            </div>
          );
        })
      }
    </div>
  );
};

const CurrentLocation = props => {
  let accuracyColor;
  let accuracy = props.accuracy;

  if (accuracy > 20) accuracyColor = 'red';
  else if (accuracy > 10) accuracyColor = 'yellow';
  else if (accuracyColor != null) accuracyColor = 'green';

  if (accuracy >= 10000) accuracy = '-';

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={3}>
            Nykyinen sijainti
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
          {'Tie: '}
          {props.roadNumber || '-'}
          </td>
          <td>
          {'Tieosa: '}
          {props.roadPart || '-'}
          </td>
          <td>
          {'Paalu: '}
          {props.roadPole || '-'}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
          {'Päivitetty: '}
          {props.time || '-'}
          </td>
          <td>
          {'Tarkkuus: '}
          <span id={'location-accuracy-' + accuracyColor}>{accuracy || '-'} </span>m
          </td>
        </tr>
      </tbody>
    </table>
  );
}


class EarthworkNew extends React.Component {

  constructor(props) {
    super(props);

    this.watchID = null;

    this.state = {
      view: 0,
      roadPart: null,
      roadDistance: null,
      mass: 0,
      attentions: "",
      loadingLocation: false,
      dailyCount: 0,
      dailyMass: 0,
      autoGPS: true,
      confirmed: false,
      paths: {},
      bankingName: '',
      useRoadInfoForBanking: false
    };

    this.saveEarthwork = this.saveEarthwork.bind(this);
    this.sendSavedEarthworks = this.sendSavedEarthworks.bind(this);
    this.changeState = this.changeState.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.useLocation = this.useLocation.bind(this);
    this.locationError = this.locationError.bind(this);
    this.confirmRoadInfo = this.confirmRoadInfo.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.toggleSetBanking = this.toggleSetBanking.bind(this);
    this.onBanking = this.onBanking.bind(this);
    this.onBank = this.onBank.bind(this);
    this.goChangeEarthwork = this.goChangeEarthwork.bind(this);
    this.clearChangeEarthwork = this.clearChangeEarthwork.bind(this);
    this.changeLocalEarthwork = this.changeLocalEarthwork.bind(this);
    this.changeEarthwork = this.changeEarthwork.bind(this);
    this.confirmRemoveEarthwork = this.confirmRemoveEarthwork.bind(this);
    this.removeEarthwork = this.removeEarthwork.bind(this);
    this.confirmRemoveLocalEarthwork = this.confirmRemoveLocalEarthwork.bind(this);
    this.removeLocalEarthwork = this.removeLocalEarthwork.bind(this);
    this.getDailyData = this.getDailyData.bind(this);
    this.setView = this.setView.bind(this);
    this.toggleAccuracyFixer = this.toggleAccuracyFixer.bind(this);
    this.getLastEarthworkData = this.getLastEarthworkData.bind(this);
  }

  componentDidMount() {
    if (localStorage['login'] == null) {
      return;
    }
    
    if (this.socket == null && typeof(WebSocket) !== 'undefined') {
      this.socket = Socket('/data/earthwork');
      this.socket.onmessage = function(e) {
        const data = JSON.parse(e.data);

        if (data['operation'] === 'create') {
          if (this.props.selectedConstructionSite.get('id') !== data.model.construction_site_id) {
            return;
          }
          this.props.addEarthwork(data.model);
        }
        else if (data['operation'] === 'update') {
          if (this.props.selectedConstructionSite.get('id') !== data.model.construction_site_id) {
            return;
          }
          this.props.changeEarthwork(data.model);
        }
        else if (data['operation'] === 'delete') {
          this.props.removeEarthwork(data.model.id);
        }
      }.bind(this);
    }

    if (typeof(Storage) !== "undefined") {
      this.setState({
        roadPart: integerValue(localStorage.roadPart, 0),
        roadDistance: integerValue(localStorage.roadDistance, 0),
        mass: integerValue(localStorage.mass, 0),
        attentions: localStorage.attentions ? localStorage.attentions : '',
        autoGPS: localStorage.autoGPS === 'false' ? false : true,
        bankingName: localStorage.bankingName ? localStorage.bankingName : '',
      });

      if (this.props.selectedConstructionSite != null) {
        this.getEarthworks(this.props.selectedConstructionSite.get('id'));
      }
    }

    this.watchID = navigator.geolocation.watchPosition(this.setLocation, this.locationError, 
      {enableHighAccuracy: true});
  }

  componentWillUpdate(nextProps, nextState) {    
    if (this.state.autoGPS !== nextState.autoGPS && nextState.autoGPS) {
      this.useLocation(true);
    }

    if (this.props.earthworks !== nextProps.earthworks) {
      this.getLastEarthworkData(nextProps.earthworks, nextState.creatingMacadam);
      this.getDailyData(nextProps.earthworks);
    }

    if (nextProps.selectedContract == null || nextProps.selectedConstructionSite == null) {
      this.props.clearEarthworks();
      return;
    }

    if (nextProps.selectedTruck != null && this.props.selectedTruck !== nextProps.selectedTruck) {
      this.setState({ mass: nextProps.selectedTruck.get('default_mass'), });
    }

    if (this.props.selectedConstructionSite === nextProps.selectedConstructionSite) return;
    this.getEarthworks(nextProps.selectedConstructionSite.get('id'));
  }

  componentWillUnmount() {
    navigator.geolocation.clearWatch(this.watchID);
    if (this.socket != null) this.socket.close();
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }

    this.setState({[propertyName]: value});
    
    if (typeof(Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  async setLocation(position) {
    let latitude = null;
    let longitude = null;
    let accuracy = null;

    if (position.coords) {
      if (this.state.externalGPS) {
        return;
      }

      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      accuracy = Math.ceil(position.coords.accuracy);
    }
    else {
      latitude = position.lat;
      longitude = position.lon;
    }

    const time = new Date();

    this.setState({
      latitude: latitude,
      longitude: longitude,
      accuracy: accuracy,
      locationTime: paddedNumber(time.getHours()) + ':' + paddedNumber(time.getMinutes()) + ':' + paddedNumber(time.getSeconds())
    });

    let road;

    if (this.props.selectedConstructionSite != null) {
     road = this.props.selectedConstructionSite.get('road_number');
    }

    const converted = toETRSTM35FIN(latitude, longitude);
    const data = await getRoadData(converted.y, converted.x, accuracy, road);

    if (data != null) {
      this.setState({
        locationError: false,
        location_road_number: data.road,
        location_road_part: data.part,
        location_road_pole: data.distance
      }, () => {
        this.useLocation(this.state.autoGPS);
      });
    }
    else {
      const data = await getRoadData(converted.y, converted.x, accuracy);

      if (data != null) {
        this.setState({
          locationError: false,
          location_road_number: data.road,
          location_road_part: data.part,
          location_road_pole: data.distance
        });
      }
      else {
        this.setState({
          locationError: true,
          location_road_number: null,
          location_road_part: null,
          location_road_pole: null
        });
      }
    }


    if (this.state.sensor && this.state.deepnessSensor) {
      this.getDeepnessSensorValue();
    }
  }

  async useLocation(autoGPS) {
    const roadNumber = this.state.location_road_number;

    if (this.props.selectedConstructionSite &&
        this.props.selectedConstructionSite.get('road_number') !== roadNumber) {
      return;
    }

    if (!autoGPS) return;

    this.setState({
      roadPart: this.state.location_road_part || '',
      roadDistance: this.state.location_road_pole || ''
      }, () => {
        if (this.state.showAccuracyFixer && this.state.accuracy < 20) {
          this.state.accuracySubmit();
        }
        localStorage.roadPart = this.state.roadPart;
        localStorage.roadDistance = this.state.roadDistance;
    });
  }

  locationError(err) {
    this.props.showMessage('Virhe', 'ERROR(' + err.code + '): ' + err.message, 'Error');
  }

  saveEarthwork(earthwork) {
    if (typeof(Storage) !== 'undefined') {
      if (localStorage['savedEarthworks'] == null) {
        localStorage['savedEarthworks'] = JSON.stringify([]);
      }

      earthwork.id = Date.now();
      earthwork.not_saved = true;

      let earthworks = JSON.parse(localStorage['savedEarthworks']);
      earthworks.push(earthwork);
      localStorage['savedEarthworks'] = JSON.stringify(earthworks);

      earthwork.date = earthwork.date + 'Z';
      this.props.addEarthwork(earthwork);
    }
    else {
      this.props.showMessage('Virhe', 'Maanajoa ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
    }
  }

  saveUpdatedEarthwork(earthwork) {
    if (typeof(Storage) !== 'undefined') {
      if (localStorage['updatedEarthwork'] == null) {
        localStorage['updatedEarthwork'] = JSON.stringify([]);
      }

      let earthworks = JSON.parse(localStorage['updatedEarthwork']);
      let existingEarthworkIndex = earthworks.findIndex(e => e.id === earthwork.id);

      if (existingEarthworkIndex) {
        earthworks[existingEarthworkIndex] = earthwork;
      }
      else {
        earthworks.push(earthwork);
      }

      localStorage['updatedEarthwork'] = JSON.stringify(earthworks);

      const currentIndex = this.props.earthworks.findIndex(e => e.get('id') === earthwork.id);
      const currentEarthwork = this.props.earthworks.get(currentIndex);
      let oldValues = {};

      oldValues.road = currentEarthwork.get('road');
      oldValues.banking_latitude = currentEarthwork.get('banking_latitude');
      oldValues.construction_site_id = currentEarthwork.get('construction_site_id');
      oldValues.latitude = currentEarthwork.get('latitude');
      oldValues.bank_longitude = currentEarthwork.get('bank_longitude');
      oldValues.date = currentEarthwork.get('date');
      oldValues.banking_date = currentEarthwork.get('banking_date');
      oldValues.back_date = currentEarthwork.get('back_date');
      oldValues.longitude = currentEarthwork.get('longitude');
      oldValues.bank_latitude = currentEarthwork.get('bank_latitude');
      oldValues.bank_road_part = currentEarthwork.get('bank_road_part');
      oldValues.bank_road_distance = currentEarthwork.get('bank_road_distance');
      oldValues.road_part = currentEarthwork.get('road_part');
      oldValues.road_distance = currentEarthwork.get('road_distance');
      oldValues.attentions = currentEarthwork.get('attentions');
      oldValues.banking_longitude = currentEarthwork.get('banking_longitude');
      oldValues.banking_road_part = currentEarthwork.get('banking_road_part');
      oldValues.banking_road_distance = currentEarthwork.get('banking_road_distance');
      oldValues.truck = currentEarthwork.get('truck');

      earthwork = Object.assign({}, oldValues, earthwork);

      earthwork.date = earthwork.date + 'Z';
      earthwork.banking_date = earthwork.banking_date ? earthwork.banking_date + 'Z' : null;
      earthwork.back_date = earthwork.back_date ? earthwork.back_date + 'Z' : null;

      this.props.changeEarthwork(earthwork);
    }
    else {
      this.props.showMessage('Virhe', 'Maanajoa ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
    }
  }

  async sendSavedEarthworks() {
    let earthworks = JSON.parse(localStorage['savedEarthworks']);
    let newEarthworks = earthworks.slice();
    let error = false;

    for (let index in earthworks) {
      const earthwork = earthworks[index];

      try {
        await fetch('/earthwork', 'POST', earthwork);
        newEarthworks.splice(newEarthworks.findIndex(newEarthwork => newEarthwork['id'] === earthwork['id']), 1);
      } catch(err) {
        console.log(err);
        error = true;
      }
    }

    localStorage['savedEarthworks'] = JSON.stringify(newEarthworks);
    this.getEarthworks(this.props.selectedConstructionSite.get('id'), error);
  }

  async sendUpdatedEarthworks() {
    let earthworks = JSON.parse(localStorage['updatedEarthwork']);
    let newEarthworks= earthworks.slice();
    let error = false;

    for (let index in earthworks) {
      const earthwork = earthworks[index];

      try {
        await fetch('/earthwork' + earthwork.id, 'PATCH', earthwork);
        newEarthworks.splice(newEarthworks.findIndex(newEarthwork => newEarthwork['id'] === earthwork['id']), 1);
      } catch(err) {
        console.log(err);
        error = true;
      }
    }

    localStorage['updatedEarthwork'] = JSON.stringify(newEarthworks);
    this.getEarthworks(this.props.selectedConstructionSite.get('id'), error);
  }

  confirmRoadInfo(callback) {
    this.setState({
      confirmed: true
    }, () => callback());
  }

  async onLoad() {
    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Kohdetta ei ole valittu', 'Warning')
      return;
    }

    if (this.state.roadPart === 0 || this.state.roadPart == null || this.state.roadPart === '') {
      this.props.showNotice('Tieosaa ei ole annettu', 'Warning')
      return;
    }

    if (this.state.roadDistance == null || this.state.roadDistance === '') {
      this.props.showNotice('Paalua ei ole annettu', 'Warning')
      return;
    }

    if (this.props.selectedTruck == null) {
      this.props.showNotice('Rekkaa ei ole valittu', 'Warning')
      return;
    }

    if (this.state.mass == null) {
      this.props.showNotice('Rekkaa ei ole valittu', 'Warning')
      return;
    }

    if (!this.state.confirmed) {
      if (!this.state.showAccuracyFixer) {
        if (!this.state.showAccuracyFixer && this.state.autoGPS && this.state.accuracy >= 20) {
          this.toggleAccuracyFixer(this.onLoad);
          return;
        }
      }
      else {
        this.setState({
          showAccuracyFixer: false
        });
      }
    }

    const roadNumber = this.props.selectedConstructionSite.get('road_number');
    const roadPart = this.state.roadPart;
    const roadDistance = this.state.roadDistance;

    try {
      if (!this.state.confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
        this.props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
                               ' Ei ole oikea tierekisteri. Haluatko jatkaa?', this.confirmRoadInfo.bind(null, this.onLoad));
        return;
      }
    } catch(error) {
      console.log(error);
    }

    this.setState({
      confirmed: false,
      loadingLoad: true
    });

    const date = new Date();
    date.setHours(date.getHours());

    const earthwork = {
      construction_site_id: this.props.selectedConstructionSite.get('id'),
      road: roadNumber,
      road_part: roadPart,
      road_distance: roadDistance,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      date: date.toISOString().replace('Z', ''),
      truck_id: this.props.selectedTruck.get('id'),
      truck: { register_number: this.props.selectedTruck.get('register_number') },
      mass: this.state.mass,
      attentions: this.state.attentions
    };

    try {
      const newEarthwork = await fetch('/earthwork', 'POST', earthwork);
      this.props.addEarthwork(newEarthwork);
    } catch(error) {
      this.saveEarthwork(earthwork);
    }

    this.props.showNotice('Kuorma lastattu', 'Ok');
    this.setState({ loadingLoad: false });
  }

  toggleSetBanking() {
    this.setState({ showSetBanking: !this.state.showSetBanking });
  }

  async onBanking() {
    if (this.state.bankingName === '') {
      this.props.showNotice('Nimeä ei ole annettu', 'Warning')
      return;
    }

    let roadNumber = this.props.selectedConstructionSite.get('road_number');
    let roadPart = null;
    let roadDistance = null;

    if (this.state.useRoadInfoForBanking) {
      if (this.state.roadPart === 0 || this.state.roadPart == null || this.state.roadPart === '') {
        this.props.showNotice('Tieosaa ei ole annettu', 'Warning')
        return;
      }
  
      if (this.state.roadDistance == null || this.state.roadDistance === '') {
        this.props.showNotice('Paalua ei ole annettu', 'Warning')
        return;
      }

      if (!this.state.confirmed) {
        if (!this.state.showAccuracyFixer) {
          if (!this.state.showAccuracyFixer && this.state.autoGPS && this.state.accuracy >= 20) {
            this.toggleAccuracyFixer(this.onBanking);
            return;
          }
        }
        else {
          this.setState({
            showAccuracyFixer: false
          });
        }
      }

      roadPart = this.state.roadPart;
      roadDistance = this.state.roadDistance;

      try {
        if (!this.state.confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
          this.props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
                                ' Ei ole oikea tierekisteri. Haluatko jatkaa?', this.confirmRoadInfo.bind(null, this.onBanking));
          return;
        }
      } catch(error) {
        console.log(error);
      }

      this.setState({
        confirmed: false
      });
    }

    const date = new Date();
    date.setHours(date.getHours());

    const earthwork = {
      id: this.props.earthworks.last().get('id'),
      banking_name: this.state.bankingName,
      banking_road_part: roadPart,
      banking_road_distance: roadDistance,
      banking_latitude: this.state.latitude,
      banking_longitude: this.state.longitude,
      banking_date: date.toISOString().replace('Z', ''),
    };

    try {
      const changedEarthwork = await fetch('/earthwork/' + earthwork.id,
                                       'PATCH', earthwork);
      this.props.changeEarthwork(changedEarthwork);
    } catch(error) {
      this.saveUpdatedEarthwork(earthwork);
    }

    this.props.showNotice('Läjitys kipattu', 'Ok');
    this.toggleSetBanking();
  }

  async onBank() {
    if (this.state.roadPart === 0 || this.state.roadPart == null || this.state.roadPart === '') {
      this.props.showNotice('Tieosaa ei ole annettu', 'Warning')
      return;
    }

    if (this.state.roadDistance == null || this.state.roadDistance === '') {
      this.props.showNotice('Paalua ei ole annettu', 'Warning')
      return;
    }

    if (!this.state.confirmed) {
      if (!this.state.showAccuracyFixer) {
        if (!this.state.showAccuracyFixer && this.state.autoGPS && this.state.accuracy >= 20) {
          this.toggleAccuracyFixer(this.onBank);
          return;
        }
      }
      else {
        this.setState({
          showAccuracyFixer: false
        });
      }
    }

    const roadNumber = this.props.selectedConstructionSite.get('road_number');
    const roadPart = this.state.roadPart;
    const roadDistance = this.state.roadDistance;

    try {
      if (!this.state.confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
        this.props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
                               ' Ei ole oikea tierekisteri. Haluatko jatkaa?', this.confirmRoadInfo.bind(null, this.onBank));
        return;
      }
    } catch(error) {
      console.log(error);
    }

    this.setState({
      confirmed: false,
      loadingBank: true
    });

    const date = new Date();
    date.setHours(date.getHours());

    const earthwork = {
      id: this.props.earthworks.last().get('id'),
      bank_road_part: roadPart,
      bank_road_distance: roadDistance,
      bank_latitude: this.state.latitude,
      bank_longitude: this.state.longitude,
      bank_date: date.toISOString().replace('Z', ''),
    };

    try {
      const changedEarthwork = await fetch('/earthwork/' + earthwork.id,
                                           'PATCH', earthwork);
      this.props.changeEarthwork(changedEarthwork);
    } catch(error) {
      this.saveUpdatedEarthwork(earthwork);
    }

    this.props.showNotice('Penger kipattu', 'Ok');

    this.setState({ loadingBank: false });
  }

  async getEarthworks(constructionSite, error=false) {
    this.setState({ loadingEarthworks: true });

    if (!error && typeof(Storage) !== 'undefined') {
      if (localStorage['savedEarthworks'] != null &&
        JSON.parse(localStorage['savedEarthworks']).length !== 0) {
        await this.sendSavedEarthworks();
        return;
      }
      if (localStorage['updatedEarthworks'] != null &&
          JSON.parse(localStorage['updatedEarthworks']).length !== 0) {
        await this.sendUpdatedEarthworks();
        return;
      }
    }

    fetch('/earthwork?site=' + constructionSite).then(async data => {
      if (localStorage['savedEarthworks'] != null && JSON.parse(localStorage['savedEarthworks']).length !== 0) {
        const savedEarthworks = JSON.parse(localStorage['savedEarthworks']).filter(
          macadam => macadam.construction_site_id === this.props.selectedConstructionSite.get('id'));
        data = data.concat(savedEarthworks);
      }

      if (localStorage['updatedEarthworks'] != null && JSON.parse(localStorage['updatedEarthworks']).length !== 0) {
        const updatedEarthworks = JSON.parse(localStorage['updatedEarthworks']).filter(
          macadam => macadam.construction_site_id === this.props.selectedConstructionSite.get('id'));
          updatedEarthworks.forEach(macadam => {
          const index = data.findIndex(d => d.id === macadam.id);
          if (index !== -1) data.splice(index, 1);
          data.push(macadam);
        });
      }
      
      this.props.addEarthworks(data);
    }).catch(error => {
      console.log(error);
      let earthworks = [];

      if (localStorage['savedEarthworks'] != null && JSON.parse(localStorage['savedEarthworks']).length !== 0) {
        const savedEarthworks = JSON.parse(localStorage['savedEarthworks']).filter(
          earthwork => earthwork.construction_site_id === this.props.selectedConstructionSite.get('id'));
        earthworks = earthworks.concat(savedEarthworks);
      }

      if (localStorage['updatedEarthworks'] != null && JSON.parse(localStorage['updatedEarthworks']).length !== 0) {
        const updatedEarthworks = JSON.parse(localStorage['updatedEarthworks']).filter(
          earthwork => earthwork.construction_site_id === this.props.selectedConstructionSite.get('id'));
          updatedEarthworks.forEach(earthwork => {
          const index = earthworks.findIndex(d => d.id === earthwork.id);
          if (index !== -1) earthworks.splice(index, 1);
          earthworks.push(earthwork);
        });
      }

      this.props.addEarthworks(earthworks);
    })
    .then(() => {
      this.setState({ loadingEarthworks: false });
    })
  }

  goChangeEarthwork(earthworkId) {
    this.setState({
      changingEarthwork: earthworkId,
    });
  }

  clearChangeEarthwork() {
    this.setState({
      changingEarthwork: null,
    });
  }

  changeEarthwork(earthwork) {
    fetch('/earthwork/' + earthwork.get('id'), 'PATCH', earthwork).then(data => {
      this.props.showNotice('Maanajo muokattu', 'Ok');
      this.props.changeMacadam(data);
      this.clearChangeEarthwork();
    }).catch(() => {
      this.saveUpdatedEarthwork(earthwork);
      this.clearChangeEarthwork();
    });
  }

  changeLocalEarthwork(earthwork) {
    let earthworks = JSON.parse(localStorage['savedEarthwork']);
    const index = earthworks.findIndex(earthwork => earthwork['id'] === this.state.changingMass.get('id'));
    earthworks[index] = earthwork;
    localStorage['savedEarthwork'] = JSON.stringify(earthworks);
    this.props.showNotice('Maanajo muokattu', 'Ok');
    this.props.changeEarthwork(earthwork);
    this.clearChangeEarthwork();
  }

  confirmRemoveEarthwork(earthworkId) {
    this.setState({
      removingEarthwork: earthworkId
    });
    this.props.showConfirm('Poistetaanko maanajo?', this.removeEarthwork);
  }

  removeEarthwork() {
    fetch('/earthwork/' + this.state.removingEarthwork + '/', 'DELETE').then(() => {
      this.props.showNotice('Maanajo poistettu', 'Ok')
      this.props.removeEarthwork(this.state.removingEarthwork);
    }).catch(() => {
      this.props.showMessage('Virhe', 'Maanajon poisto epäonnistui', 'Error');
    });
  }

  removeLocalEarthwork() {
    // Without timer does not work. Error: (Reducers may not dispatch actions.)
    timer(0).then(() => {
      let earthworks = JSON.parse(localStorage['savedEarthwork']);
      earthworks = earthworks.filter(earthwork => earthwork['id'] !== this.state.removingEarthwork);
      localStorage['savedEarthwork'] = JSON.stringify(earthworks);
      this.props.showNotice('Maanajo poistettu', 'Ok')
      this.props.removeMacadam(this.state.removingEarthwork);
    });
  }

  confirmRemoveLocalEarthwork(earthworkId) {
    this.setState({
      removingEarthwork: earthworkId,
    });
    this.props.showConfirm('Poistetaanko maanajo?', this.removeLocalEarthwork);
  }


  getDailyData(earthworks) {
    let dailyCount = 0;
    let dailyMass = 0;
    let wholeMass = 0;
    let now = new Date();

    now.setHours(now.getHours());

    let past12HoursFromNow = new Date(now);
    past12HoursFromNow.setHours(past12HoursFromNow.getHours() - 12);

    let newDay = false;

    for (let i = earthworks.size - 1; i >= 0; i--) {
      const earthwork = earthworks.get(i);
      const date = new Date(earthwork.get('date'));

      if (!newDay && date >= past12HoursFromNow &&
          date <= now) {
        dailyCount++;
        dailyMass += parseFloat(earthwork.get('mass'));   
      }

      if (i !== 0) {
        let past6Hours = new Date(date);
        past6Hours.setHours(past6Hours.getHours() - 6);
        let beforeEarthworkDate = new Date(earthworks.get(i - 1).get('date'));
        beforeEarthworkDate.setHours(beforeEarthworkDate.getHours());
        if (past6Hours >= beforeEarthworkDate) {
          newDay = true;
        }
      }

      wholeMass += parseFloat(earthwork.get('mass'));
    }

    this.setState({
      dailyCount: dailyCount,
      dailyMass: dailyMass,
      wholeMass: wholeMass
    });
  }

  setView(view) {
    this.setState({
      view: view
    });
  }

  toggleAccuracyFixer(callback) {
    this.setState({
      showAccuracyFixer: !this.state.showAccuracyFixer,
      accuracyFixerCallback: callback
    });
  }

  getLastEarthworkData(earthworks) {
    let lastLoadPart = 0;
    let lastLoadDistance = 0;
    let lastTruck = null;
    let lastMass = null;
    let lastBankingName = null;
    let lastBankingLatitude = null;
    let lastBankingLongitude = null;
    let lastBankLatitude = null;
    let lastBankLongitude = null;

    const lastEarthwork = earthworks.last();

    if (lastEarthwork != null) {
      lastLoadPart = lastEarthwork.get('road_part');
      lastLoadDistance = lastEarthwork.get('road_distance');
      lastTruck = lastEarthwork.get('truck').get('register_number');
      lastMass = lastEarthwork.get('mass');
      lastBankingName = lastEarthwork.get('banking_name');
      lastBankingLatitude = lastEarthwork.get('banking_latitude');
      lastBankingLongitude = lastEarthwork.get('banking_longitude');
      lastBankLatitude = lastEarthwork.get('bank_latitude');
      lastBankLongitude = lastEarthwork.get('bank_longitude');
    }

    this.setState({
      lastLoadPart: lastLoadPart,
      lastLoadDistance: lastLoadDistance,
      lastTruck: lastTruck,
      lastMass: lastMass,
      lastBankingName: lastBankingName,
      lastBankingLatitude: lastBankingLatitude,
      lastBankingLongitude: lastBankingLongitude,
      lastBankLatitude: lastBankLatitude,
      lastBankLongitude: lastBankLongitude
    });
  }

  render() {
    return (
      <div>
        <TopContractAndSiteSelect store={this.props.store} />
        <div className="container">
          <h1>Maanajo</h1>
          <div className='button-area'>
            <button className='button-view' onClick={this.setView.bind(this, 0)}
                    disabled={this.state.view === 0}>
              Lisää
            </button>
            <button className='button-view' onClick={this.setView.bind(this, 2)}
                    disabled={this.state.view === 2}>
              Seurantatiedot
            </button>
          </div>
          <View view={this.state.view} changeState={this.changeState} roadPart={this.state.roadPart}
                roadDistance={this.state.roadDistance}
                road={this.props.selectedConstructionSite ? this.props.selectedConstructionSite.get('road_number') : '-'}
                onLoad={this.onLoad} useLocation={this.useLocation}
                mass={this.state.mass}
                attentions={this.state.attentions}
                dailyMass={this.state.dailyMass}
                dailyCount={this.state.dailyCount} wholeMass={this.state.wholeMass}
                earthworks={this.props.earthworks} selectedConstructionSite={this.props.selectedConstructionSite}
                loading={this.state.loadingEarthworks} confirmRemoveEarthwork={this.confirmRemoveEarthwork}
                confirmRemoveLocalEarthwork={this.confirmRemoveLocalEarthwork}
                removeLastPath={this.confirmRemoveLastPath}
                location_road_number={this.state.location_road_number} location_road_part={this.state.location_road_part}
                location_road_pole={this.state.location_road_pole} accuracy={this.state.accuracy}
                locationTime={this.state.locationTime} latitude={this.state.latitude} longitude={this.state.longitude}
                showMessage={this.props.showMessage} changeEarthwork={this.goChangeEarthwork}
                autoGPS={this.state.autoGPS}
                loadingLoad={this.state.loadingLoad}
                loadingBank={this.state.loadingBank}
                lastLoadPart = {this.state.lastLoadPart}
                lastLoadDistance = {this.state.lastLoadDistance}
                lastMass = {this.state.lastMass}
                lastTruck = {this.state.lastTruck}
                toggleSetBanking={this.toggleSetBanking}
                onBank={this.onBank}
                lastBankLatitude={this.state.lastBankLatitude}
                lastBankingLatitude={this.state.lastBankingLatitude}
                store={this.props.store}
            />
          <ChangeEarthworkView earthwork={this.state.changingEarthwork} clear={this.clearChangeEarthwork}
                               changeEarthwork={this.changeEarthwork} changeLocalEarthwork={this.changeLocalEarthwork}/>
          <SetBankingName show={this.state.showSetBanking} toggle={this.toggleSetBanking}
                          submit={this.onBanking} bankingName={this.state.bankingName}
                          useRoadInfoForBanking={this.state.useRoadInfoForBanking} changeState={this.changeState} />
          <AccuracyFixer show={this.state.showAccuracyFixer} toggle={this.toggleAccuracyFixer}
                         submit={this.state.accuracyFixerCallback} accuracy={this.state.accuracy}/>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  earthworks: state.earthwork.get('earthworks'),
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  selectedTruck: state.truckSelect.get('selectedTruck'),
}), { clearEarthworks, addEarthworks, removeEarthwork, changeEarthwork, showNotice,  showConfirm,
      selectTruck, showMessage, addEarthwork })(EarthworkNew);
