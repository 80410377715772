import React from 'react';
import L from 'leaflet';
import { Map, Marker, Popup, TileLayer, Polyline } from 'react-leaflet';
import { connect } from 'react-redux';
import { ContractSelect, ConstructionSiteSelect, List, Excel, showMessage,
         MapTiles } from 'components';
import ConstructionSiteLatestSelect from './ConstructionSiteLatestSelect';
import { clearEarthworks, addEarthworks, removeEarthwork, changeEarthwork } from './Actions';
import { fetch, Socket, toRadians, toETRSTM35FIN } from '../utils';
import './Earthwork.css';
import TimeRange from '../timeRange/TimeRange';

const paddedNumber = number => number <= 99 ? ('0' + number).slice(-2) : number;


const MapOrList = props => {
  let earthworks = props.earthworks;

  if (props.site === null) earthworks = props.earthworks.clear();

  if (props.state === 0) return <MapView earthworks={earthworks}
                                         site={props.site}
                                         mapPosition={props.mapPosition}
                                         mapZoom={props.mapZoom} />;

  let totalMass = 0;

  earthworks.forEach(earthwork => {
    totalMass += earthwork.get('mass');
  });

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              Kuorma tonnit
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Yhteensä:</b>
            </td>
            <td>
              {Math.round(totalMass * 10000) / 10000}
            </td>
          </tr>
        </tbody>
      </table>
      <List emptyText={'Ei maanajoja'}
            header={['Aika', 'Lastaus tieosa', 'Lastaus paalu', 'Ajoneuvo',
                     'Kuorman koko', 'Läjitysalue', 'Läjitys tieosa', 'Läjitys paalu',
                     'Penger tieosa', 'Penger paalu', 'Huomiot']}
            fields={['date#time', 'road_part', 'road_distance', 'truck.register_number',
                    'mass#round', 'banking_name', 'banking_road_part', 'banking_road_distance',
                    'bank_road_part', 'bank_road_distance', 'attentions']}
            data={earthworks}/>
    </div>
  )
};


export class MapView extends React.Component {

  constructor(props){
    super(props);
    this.update = this.update.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.selectLine = this.selectLine.bind(this);

    this.position = [64.1, 26.5];
    this.zoom = 6;

    this.state = {
      showedWarning: false,
      lines: [],
      yourLaditude: null,
      yourLongitude: null,
      mapTilesUrl: '',
      mapTilesAttribution: '',
      maxZoom: 16
    };
  }

  componentWillMount() {
    this.updateDimensions();
    this.update(this.props, this.state);

    if (!navigator.geolocation) {
      return;
    }

    this.watchID = navigator.geolocation.watchPosition(position => {
      this.setState({
        yourLaditude: position.coords.latitude,
        yourLongitude: position.coords.longitude
      });
    }, error => {
      return;
    }, {enableHighAccuracy: true});
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions)
  }

  componentWillUnmount() {
    navigator.geolocation.clearWatch(this.watchID);
    window.removeEventListener("resize", this.updateDimensions);
    clearInterval(this.truckUpdateInterval);
  }

  componentWillUpdate(nextProps, nextState) {
    const mapCenter = this.map.leafletElement.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng)
    const mapTiles = MapTiles(converted.x, converted.y);

    if (this.state.mapTilesUrl !== mapTiles.url) {
      this.setState({
        mapTilesUrl: mapTiles.url,
        mapTilesAttribution: mapTiles.attribution,
        maxZoom: mapTiles.maxZoom
      });
    }

    if (this.state.selectedLine !== nextState.selectedLine ||
        this.props.earthworks !== nextProps.earthworks) {
      this.update(nextProps, nextState);
    }
  }

  updateDimensions() {
    const height = window.innerHeight * 0.6;
    this.setState({ height: height })
  }

  async update(props, state) {
    let lines = [];

    for (let i = 0; i < props.earthworks.size; i++) {
      const earthwork = props.earthworks.get(i);

      const weight = 13;
      const opacity = 0.9;
      let positions = [[earthwork.get('latitude'), earthwork.get('longitude')]];

      let date = new Date(earthwork.get('date'));
      let time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
                  + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

      let color = '#00FF00';

      if (props.selectedLine === earthwork.get('id')) {
        color = '#00FFFF';
      }

      let text = (
        <span>
          <strong>Lastaus</strong>
          <br/>
          {time}
          <br/>
          {earthwork.get('road')} / {earthwork.get('road_part')} / {earthwork.get('road_distance')}
          <br/>
          Rekisterinumero: {earthwork.get('truck').get('register_number')}
          <br/>
          Kuorman massa: {earthwork.get('mass')}
        </span>
      );

      lines.push(
        <Polyline key={earthwork.get('id') + '-load'}
                  positions={[positions[0], positions[0]]}
                  weight={weight}
                  opacity={opacity}
                  color={color}
                  onClick={this.selectLine.bind(null, earthwork.get('id'))}
                >
          <Popup autoPan={false}>
            {text}
          </Popup>
        </Polyline>
      );

      if (earthwork.get('banking_latitude')) {
        date = new Date(earthwork.get('banking_date'));
        time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
                    + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

        text = (
          <span>
            <strong>Läjitys</strong>
            <br/>
            {time}
            <br/>
            {earthwork.get('banking_name')}
            <br/>
            {earthwork.get('banking_road_part') ?
              earthwork.get('road') + ' / ' + earthwork.get('banking_road_part') + ' / ' + earthwork.get('banking_road_distance')
              : null
            }
            <br/>
            Rekisterinumero: {earthwork.get('truck').get('register_number')}
            <br/>
            Kuorman massa: {earthwork.get('mass')}
          </span>
        );

        if (props.selectedLine === earthwork.get('id')) {
          color = '#00FFFF';
        }
        else {
          color = '#FF00FF';
        }

        lines.push(
          <Polyline key={earthwork.get('id') + '-banking'}
                    positions={[[earthwork.get('banking_latitude'), earthwork.get('banking_longitude')],
                                [earthwork.get('banking_latitude'), earthwork.get('banking_longitude')]]}
                    weight={weight}
                    opacity={opacity}
                    color={color}
                    onClick={this.selectLine.bind(null, earthwork.get('id'))}
                  >
            <Popup autoPan={false}>
              {text}
            </Popup>
          </Polyline>
        );

        positions.push([earthwork.get('banking_latitude'), earthwork.get('banking_longitude')]);
      }
      
      if (earthwork.get('bank_latitude')) {
        date = new Date(earthwork.get('bank_date'));
        time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
                    + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

        text = (
          <span>
            <strong>Penger</strong>
            <br/>
            {time}
            <br/>
            {earthwork.get('road')} / {earthwork.get('bank_road_part')} / {earthwork.get('bank_road_distance')}
            <br/>
            Rekisterinumero: {earthwork.get('truck').get('register_number')}
            <br/>
            Kuorman massa: {earthwork.get('mass')}
          </span>
        );

        if (props.selectedLine === earthwork.get('id')) {
          color = '#00FFFF';
        }
        else {
          color = '#0000FF';
        }

        lines.push(
          <Polyline key={earthwork.get('id') + '-bank'}
                    positions={[[earthwork.get('bank_latitude'), earthwork.get('bank_longitude')],
                                [earthwork.get('bank_latitude'), earthwork.get('bank_longitude')]]}
                    weight={weight}
                    opacity={opacity}
                    color={color}
                    onClick={this.selectLine.bind(null, earthwork.get('id'))}
                  >
            <Popup autoPan={false}>
              {text}
            </Popup>
          </Polyline>
        );

        positions.push([earthwork.get('bank_latitude'), earthwork.get('bank_longitude')]);
      }

      if (positions.length > 1) {
        lines.push(
          <Polyline key={earthwork.get('id')}
                    positions={positions}
                    weight={2}
                    opacity={0.9}
                    color={'#00FFFF'}
                  >
          </Polyline>
        );
      }
    }

    this.setState({ lines: lines })
  }

  selectLine(index) {
    this.setState({
      selectedLine: index
    })
  }

  render() {
    return (
      <div>
        <div style={{ height: this.state.height }}>
          <link rel='stylesheet' href='https://unpkg.com/leaflet@1.4.0/dist/leaflet.css'
                integrity='sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=='
                crossOrigin='' />
          <Map id='map-area' center={this.props.mapPosition} zoom={this.props.mapZoom} maxZoom={this.state.maxZoom}
               onClick={this.selectLine.bind(null, null)}
               ref={element => this.map = element}>
            <TileLayer url={this.state.mapTilesUrl}
                       attribution={this.state.mapTilesAttribution}
                       maxZoom={this.state.maxZoom} />
            { this.props.site != null && this.props.site.get('coating_plant_laditude') != null ? (
                <Marker position={[this.props.site.get('coating_plant_laditude'), this.props.site.get('coating_plant_longitude')]}
                        icon={new L.Icon({iconUrl: 'coating_plant.gif',
                              iconSize: [33, 50],
                              iconAnchor: [17, 50],
                              popupAnchor: [null, -50]
                              })}>
                  <Popup autoPan={false}>
                    <span>{this.props.site.get('coating_plant_laditude')}, {this.props.site.get('coating_plant_longitude')}</span>
                  </Popup>
                </Marker>
              ) : null }
            { this.state.yourLaditude != null ? (
                <Marker position={[this.state.yourLaditude, this.state.yourLongitude]}
                        icon={new L.Icon({iconUrl: 'your_location.gif',
                              iconSize: [18, 43],
                              iconAnchor: [9, 43],
                              popupAnchor: [null, -43]
                              })}>
                  <Popup autoPan={false}>
                    <span>{this.state.yourLaditude}, {this.state.yourLongitude}</span>
                  </Popup>
                </Marker>
              ) : null }
            {this.state.lines}
          </Map>
        </div>
        {this.state.showedWarning ? 'Yhtä tai useampaa ei voida näyttää sijainti tiedon virheellisyyden vuoksi.' : ''}
      </div>
    );
  }
};


class Earthworks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      state: 0,
      loading: false,
      makeExcel: false,
      mapPosition: [64.1, 26.5],
      mapZoom: 6
    };

    this.changeState = this.changeState.bind(this);
    this.toggleMakeExcel = this.toggleMakeExcel.bind(this);
  }

  componentDidMount() {
    if (this.socket == null && typeof(WebSocket) !== 'undefined') {
      this.socket = Socket('/data/earthwork');
      this.socket.onmessage = function(e) {
        const data = JSON.parse(e.data);
        if (data['operation'] === 'create') {
          if (data.model.construction_site) {
            if (this.props.selectedConstructionSite.get('id') !== data.model.construction_site_id) {
              return;
            }
          }

          if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
            this.getEarthworks(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
          }
          else {
            this.getEarthworks(this.props.selectedConstructionSite.get('id'));
          }
        }
        else if (data['operation'] === 'update') {
          if (data.model.construction_site) {
            if (this.props.selectedConstructionSite.get('id') !== data.model.construction_site_id) {
              return;
            }
            this.props.changeEarthwork(data.model);
          }
        }
        else if (data['operation'] === 'delete') {
          this.props.removeEarthwork(data.model.id);
        }
      }.bind(this)
    }
    if (!this.props.selectedConstructionSite) return;
    if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
      this.getEarthworks(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
    }
    else {
      this.getEarthworks(this.props.selectedConstructionSite.get('id'));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedConstructionSite === nextProps.selectedConstructionSite &&
        this.props.timeRangeStart === nextProps.timeRangeStart &&
        this.props.timeRangeEnd === nextProps.timeRangeEnd) return;
    if (nextProps.selectedContract == null || nextProps.selectedConstructionSite == null) {
      this.props.clearEarthworks();
      return;
    }
    if (nextProps.timeRangeStart !== '' && nextProps.timeRangeEnd !== '') {
      this.getEarthworks(nextProps.selectedConstructionSite.get('id'), nextProps.timeRangeStart, nextProps.timeRangeEnd);
    }
    else {
      this.getEarthworks(nextProps.selectedConstructionSite.get('id'));
    }
  }

  componentWillUnmount() {
    if (this.socket != null) this.socket.close();
  }

  async getEarthworks(site, startTime, endTime) {
    this.setState({ loading: true });

    let url;

    if (startTime != null) {
      url = '/earthwork?site=' + site + '&timestart=' + startTime
            + '&timeend=' + endTime;
    }
    else {
      url = '/earthwork?site=' + site;
    }

    let position = [64.1, 26.5];
    let zoom = 6;

    fetch(url).then(data => {
      let x = 0;
      let y = 0;
      let z = 0;

      data.forEach(earthwork => {
        earthwork.banking_road_part = earthwork.banking_road_part || '-';
        earthwork.banking_road_distance = earthwork.banking_road_distance || '-';
        earthwork.bank_road_part = earthwork.bank_road_part || '-';
        earthwork.bank_road_distance = earthwork.bank_road_distance || '-';

        const startLatitude = toRadians(earthwork.latitude);
        const startLongitude = toRadians(earthwork.longitude);
        x += Math.cos(startLatitude) * Math.cos(startLongitude);
        y += Math.cos(startLatitude) * Math.sin(startLongitude);
        z += Math.sin(startLatitude);
      });

      this.props.addEarthworks(data);

      if (data.length !== 0) {
        x = x / data.length;
        y = y / data.length;
        z = z / data.length;

        const centralLongitude = Math.atan2(y, x);
        const centralSquareRoot = Math.sqrt(x * x + y * y);
        const centralLatitude = Math.atan2(z, centralSquareRoot);

        position = [centralLatitude * 180 / Math.PI, centralLongitude * 180 / Math.PI]
        zoom = 15;
      }

    }).catch(() => {
      this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
    }).then(() => {
      this.setState({
        loading: false,
        mapPosition: position,
        mapZoom: zoom
       });
    });
  }

  changeState(state) {
    this.setState({
      state: state
    });
  }

  toggleMakeExcel() {
    this.setState({ 
      makeExcel: !this.state.makeExcel
    })
  }

  render() {
    let id = null;
    let name = null;

    if (this.props.selectedConstructionSite != null) {
       id = this.props.selectedConstructionSite.get('id');
       name = this.props.selectedConstructionSite.get('name');
    }

    return (
      <div>
        <div className='container'>
          <h1>Maanajot</h1>
          <ConstructionSiteLatestSelect get='earthwork' />
          <div className="row">
            <div className="column">
              <ContractSelect store={this.props.store} />
            </div>
            <div className="column">
              <ConstructionSiteSelect store={this.props.store} />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <TimeRange />
            </div>
            <div className="column">
              <button onClick={this.toggleMakeExcel} disabled={id == null || this.props.earthworks.size === 0}>Luo raportti</button>
            </div>
          </div>
        </div>
        <div className='wide-area'>
          <fieldset id="data">
            <legend>
              <div>
                  <div className={"state" + (this.state.state === 0 ? ' selected' : '')}
                       onClick={this.state.state === 0 ? null : this.changeState.bind(null, 0)}>
                    Kartta
                  </div>
                  <div className={"state" + (this.state.state === 1 ? ' selected' : '')}
                       onClick={this.state.state === 1 ? null : this.changeState.bind(null, 1)}>
                    Lista
                  </div>
              </div>
            </legend>
            <MapOrList state={this.state.state} earthworks={this.props.earthworks} site={this.props.selectedConstructionSite}
                      loading={this.state.loading} page={this.state.page} changePage={this.changePage}
                      mapPosition={this.state.mapPosition} mapZoom={this.state.mapZoom} />
          </fieldset>
          { this.state.loading ? <div className="main loader"/> : null }
        </div>
        { name != null ?
          <div>
            <Excel show={this.state.makeExcel} toggle={this.toggleMakeExcel}
                  name={name.length > 31 ? id.toString() : name}
                  headers={['Aika', 'Lastaus tieosa', 'Lastaus paalu', 'Ajoneuvo',
                            'Kuorman koko', 'Läjitys aika', 'Läjitysalue', 'Läjitys tieosa', 'Läjitys paalu',
                            'Penger aika', 'Penger tieosa', 'Penger paalu', 'Huomiot']}
                  dataHeaders={['date', 'road_part', 'road_distance', 'truck.register_number',
                                'mass', 'banking_date', 'banking_name', 'banking_road_part', 'banking_road_distance',
                                'bank_date', 'bank_road_part', 'bank_road_distance', 'attentions']}
                  timeField={['date', 'banking_date', 'bank_date']}
                  data={this.props.earthworks}/>
          </div>
          :
          null
        }
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  timeRangeStart: state.timeRange.get('startTime'),
  timeRangeEnd: state.timeRange.get('endTime'),
  earthworks: state.earthwork.get('earthworks'),
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null,
}), { clearEarthworks, addEarthworks, removeEarthwork, changeEarthwork, showMessage })(Earthworks);
