import React from "react";
import { combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";
import {
  Login,
  Logout,
  LoginReducer,
  MenuReducer,
  MessageReducer,
  NoticeReducer,
  ConfirmReducer,
  ContractSelectReducer,
  ConstructionSiteSelectReducer,
  TruckSelectReducer,
  TimeRangeReducer,
} from "components";
import EarthworkReducer from "../earthwork/Reducer";

import App from "./Application.js";
import EarthworkNew from "../earthwork/EarthworkNew.js";
import Earthworks from "../earthwork/Earthworks.js";
import Trucks from "../truck/Trucks.js";
import TruckNew from "../truck/TruckNew.js";
import TruckEdit from "../truck/TruckEdit.js";

export const store = createStore(
  combineReducers({
    login: LoginReducer,
    menu: MenuReducer,
    message: MessageReducer,
    notice: NoticeReducer,
    confirm: ConfirmReducer,
    earthwork: EarthworkReducer,
    constructionSiteSelect: ConstructionSiteSelectReducer,
    contractSelect: ContractSelectReducer,
    truckSelect: TruckSelectReducer,
    timeRange: TimeRangeReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const AppWithLocation = ({ store }) => {
  const location = useLocation();

  return <App store={store} location={location} />;
};

const TruckEditWithParams = ({ store }) => {
  const params = useParams();

  return <TruckEdit store={store} params={params} />;
};

const Root = () => (
  <Provider store={store}>
    <span id="timer" />
    <BrowserRouter>
      <AppWithLocation store={store} />
      <Routes>
        <Route path="/login" element={<Login store={store} />} />
        <Route path="/logout" element={<Logout store={store} />} />
        <Route path="/new" element={<EarthworkNew store={store} />} />
        <Route path="/earthworks" element={<Earthworks store={store} />} />
        <Route path="/trucks" element={<Trucks store={store} />} />
        <Route path="/trucks/new" element={<TruckNew store={store} />} />
        <Route path="/truck/:id" element={<TruckEditWithParams store={store} />} />
        <Route path="/" element={<></>} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

export default Root;
