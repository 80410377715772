import React from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import { Map, Marker, Popup, TileLayer, Polyline } from 'react-leaflet';
import { TruckSelect, selectTruck, showNotice, showConfirm, MapTiles } from 'components';
import { fetch, testIsValidRoadInfo, paddedNumber, stateValueParser } from '../utils.js';


export const RoadwaySelect = props => (
  <div>
    <label>Ajorata</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio'>
      0
      <input type='radio' name='roadway' value='0'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 0}/>
    </label>
    <label className='radio'>
      1
      <input type='radio' name='roadway' value='1'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 1}/>
    </label>
    <label className='radio'>
      2
      <input type='radio' name='roadway' value='2'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 2}/>
    </label>
  </div>
);

export const DirectionSelect = props => (
  <div>
    <label>Suunta</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio'>
      1
      <input id='direction1' type='radio' name='direction' value='1'
        onClick={props.onChange.bind(this, 'direction', 'integer', null)}
        defaultChecked={props.direction === 1}/>
    </label>
    <label className='radio'>
      2
      <input id='direction2' type='radio' name='direction' value='2'
        onClick={props.onChange.bind(this, 'direction', 'integer', null)}
        defaultChecked={props.direction === 2}/>
    </label>
  </div>
);

export const LaneSelect = props => (
  <div>
    <label>Kaista</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio'>
      1
      <input type='radio' name='line' value='1'
        onClick={props.onChange.bind(this, 'lane', 'integer', null)} 
        defaultChecked={props.lane === 1}/>
    </label>
    <label className='radio'>
      2
      <input type='radio' name='line' value='2'
        onClick={props.onChange.bind(this, 'lane', 'integer', null)} 
        defaultChecked={props.lane === 2}/>
    </label>
  </div>
);

export const AccuracyFixer = props => {
  if (!props.show) return null;
  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='accuracy-modal'>
        <h4>Korjataan sijaintia epätarkkuuden takia...</h4>
        <h5>Tämän hetkinen tarkkuus: {props.accuracy} m</h5>
        <div className='loader'/>
        <br/>
        <button onClick={props.submit}>
          Hyväksy silti
        </button>
        <button onClick={props.toggle}>
          Syötä manuaalisesti
        </button>
      </div>
    </div>
  );
}

export const SetBankingName = props => {
  if (!props.show) return null;
  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='banking-modal'>
        <h4>Läjitysalue</h4>
        <label htmlFor='bankingName'>Nimi</label>
        <input id='bankingName' type='text' value={props.bankingName || ''}
               onChange={props.changeState.bind(this, 'bankingName', 'string', '')} />
        <label className='checkbox'>
          Käytä tierekisteriä
        <input type='checkbox'
               onChange={props.changeState.bind(this, 'useRoadInfoForBanking', 'boolean', true)} 
               value={props.useRoadInfoForBanking} />
        </label>
        <br/>
        <button onClick={props.submit}>
          Ok
        </button>
        <button className='button-outline' onClick={props.toggle}>
          Peruuta
        </button>
      </div>
    </div>
  );
}

class ChangeEarthworkView extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      roadPart: null,
      roadDistance: null,
      mass: null,
      attentions: null,
      confirmedRoad: false
    };

    this.changeState = this.changeState.bind(this);
    this.doChangeEarthwork = this.doChangeEarthwork.bind(this);
    this.confirmRoadInfo = this.confirmRoadInfo.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.earthwork == null) return;

    if (nextProps.selectedTruck != null && this.props.selectedTruck !== nextProps.selectedTruck) {
      this.setState({
        truckRegisterNumber: nextProps.selectedTruck.get('register_number'),
      });
    }

    if (nextProps.earthwork === this.props.earthwork) return;

    this.props.selectTruck(nextProps.earthwork.get('truck'));

    this.setState({
      roadPart: nextProps.earthwork.get('road_part'),
      roadDistance: nextProps.earthwork.get('road_distance'),
      mass: nextProps.earthwork.get('mass'),
      attentions: nextProps.earthwork.get('attentions')
    });
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }
    
    this.setState({[propertyName]: value});
  }

  confirmRoadInfo() {
    this.setState({
      confirmedRoad: true
    }, () => this.doChangeEarthwork());
  }

  async doChangeEarthwork() {
    if (this.state.roadPart === 0) {
      this.props.showNotice('Tieosaa ei ole annettu', 'Warning');
      return;
    }

    if (this.props.selectedTruck == null) {
      this.props.showNotice('Rekkaa ei ole valittu', 'Warning');
      return;
    }

    const mass = parseFloat(this.state.mass);
    if (mass === 0) {
      this.props.showNotice('Kuorman massaa ei ole annettu', 'Warning');
      return;
    }

    const roadNumber = this.props.earthwork.get('road');
    const roadPart = this.state.roadPart;
    const roadDistance = this.state.roadDistance;

    if (!this.state.confirmedRoad) {
      let text = '';
      if (!await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
        text += roadNumber + '/' + roadPart + '/' + roadDistance +
                ' Ei ole oikea tierekisteri. ';
      }
      if (text !== '') {
        this.props.showConfirm(text + 'Haluatko jatkaa?', this.confirmRoadInfo);
        return;
      }
    }

    this.setState({
      confirmedRoad: false
    });

    let earthwork = this.props.earthwork;
    earthwork = earthwork.set('road_part', this.state.roadPart);
    earthwork = earthwork.set('road_distance', this.state.roadDistance);
    earthwork = earthwork.set('truckId', this.props.selectedTruck.get('id'));
    earthwork = earthwork.set('truck', null);
    earthwork = earthwork.set('mass', mass);
    earthwork = earthwork.set('attentions', this.state.attentions);

    if (earthwork.get('not_saved')) {
      this.props.changeLocalEarthwork(earthwork);
    }
    else {
      this.props.changeEarthwork(earthwork);
    }
  }

  render() {
    if (this.props.earthwork == null) return null;

    return (
      <div onClick={this.props.clear} className='modal'>
        <div onClick={e => e.stopPropagation()} id='load-data-modal'>
          <h3 className='center'>Muokkkaa</h3>
          <div className='row'>
            <div className='column'>
              <label htmlFor='road'>Tie</label>
              <input id='road' type='text' value={this.props.earthwork.get('road') || ''} readOnly/>
            </div>
            <div className='column'>
              <label htmlFor='roadPart'>Lastaus tieosa</label>
              <input id='roadPart' type='number' step='any' min='0'
                    value={this.state.roadPart}
                    onChange={this.changeState.bind(this, 'roadPart', 'integer', 0)} required/>
            </div>
            <div className='column'>
              <label htmlFor='pole'>Lastaus paalu</label>
              <input id='pole' type='number' step='any' min='0'
                    value={this.state.roadDistance}
                    onChange={this.changeState.bind(this, 'roadDistance', 'integer', 0)} required/>
            </div>
          </div>
        <div className='row'>
          <div className='column'>
            <label htmlFor='truck'>Rekka</label>
              <TruckSelect required/>
          </div>
          <div className='column'>
            <label htmlFor='mass'>Kuorma (Tonnit)</label>
              <input id='mass' type='tel'
                     value={this.state.mass}
                     onChange={this.changeState.bind(this, 'mass', 'float', 0.0)} required/>
          </div>
          <div className='column'>
            <label htmlFor='attentions'>Huomiot</label>
            <input id='attentions' type='text' value={this.state.attentions || ''}
                  onChange={this.changeState.bind(this, 'attentions', 'string', '')}/>
          </div>
        </div>
        <div className='row'>
          <div className='column'>
            <button onClick={this.doChangeEarthwork}>
              Muokkaa
            </button>
          </div>
          <div className='column'>
            <button className='button-outline' onClick={this.props.clear}>
              Peruuta
            </button>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  selectedTruck: state.truckSelect.get('selectedTruck'),
  trucks: state.truckSelect.get('trucks'),
}), { selectTruck, showNotice, showConfirm })(ChangeEarthworkView);


export class MapView extends React.Component {

  constructor(props){
    super(props);
    this.update = this.update.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.getTrucks = this.getTrucks.bind(this);

    this.trucks = [];
    this.position = [64.1, 26.5];
    this.zoom = 6;
    this.truckUpdateInterval = null;
    this.map = null;

    this.state = {
      showedWarning: false,
      directions: [1, 2],
      trucks: [],
      lines: [],
      mapTilesUrl: '',
      mapTilesAttribution: ''
    };
  }

  componentWillMount() {
    if (this.props.mapPosition) {
      this.position = this.props.mapPosition;
      this.zoom = this.props.mapZoom;
    }
    
    this.updateDimensions();
    this.update(this.props, this.state);
    this.getTrucks();
    this.truckUpdateInterval = setInterval(this.getTrucks, 5000);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    clearInterval(this.truckUpdateInterval);
  }

  componentDidUpdate(prevProps, prevState) {
    const mapCenter = this.map.leafletElement.getCenter();
    const mapTiles = MapTiles(mapCenter.lat, mapCenter.lng);

    if (this.state.mapTilesUrl !== mapTiles.url) {
      this.setState({
        mapTilesUrl: mapTiles.url,
        mapTilesAttribution: mapTiles.attribution
      })
    }

    if (this.state.directions !== prevState.directions ||
        this.state.selectedLine !== prevState.selectedLine ||
        this.props.mapPaths !== prevProps.mapPaths) {
      this.update(this.props, this.state);
    }
  }

  async update(props, state) {
    if (props.mapPaths.length === 0) {
      return;
    }

    let lines = [];

    for (let i = 0; i < props.earthworks.size; i++) {
      const earthwork = props.earthworks.get(i);

      for (let p = 0; p < earthwork.get('paths').size; p++) {
        const path =  earthwork.get('paths').get(p);

        if (!(state.directions.includes(path.get('direction'))) || !path.get('start_latitude')) continue;

        const date = new Date(path.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
        + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

        let color;

        if (state.selectedLine === path.get('id')) {
          color = '#00FFFF';
        }
        else if (path.get('direction') === 1) {
          color = '#0000FF';
        }
        else {
          color = '#00FF00';
        }

        const text = (
          <span>
            {time}
            <br/>
            Aloitus: {path.get('road')} / Osa: {path.get('start_part')} / Paalu: {path.get('start_distance')}
            <br/>
            Lopetus: {path.get('road')} / Osa: {path.get('end_part') || '- '} / Paalu: {path.get('end_distance') || '-'}
            <br/>
            Ajorata: {path.get('roadway')}
            <br/>
            Suunta: {path.get('direction')}
            <br/>
            Kaista: {path.get('lane')}
            <br/>
            Rekisterinumero: {earthwork.get('truck').get('register_number')}
            <br/>
            { earthwork.get('paths').size > 1 ?
              <div>
                Arvio käytetystä massasta: {Math.round(earthwork.get('truck_mass') / earthwork.get('paths').size * 100) / 100}
              </div>
              : null
            }
            Kuorman koko: {Math.round(earthwork.get('truck_mass') * 100) / 100}
            <br/>
          </span>);

        lines.push(
          <Polyline key={path.get('id')}
                    positions={props.mapPaths[path.get('id')]}
                    weight={10}
                    opacity={0.6}
                    color={color}
                    onClick={this.selectLine.bind(null, path.get('id'))}
                  >
            <Popup autoPan={false}>
              {text}
            </Popup>
          </Polyline>
        );
      }
    }

    this.setState({ lines: lines })
  }

  updateDimensions() {
    const height = window.innerHeight * 0.6;
    this.setState({ height: height })
  }

  getTrucks() {
    let trucks = [];

    fetch('/vehicles/' + this.props.organizationId).then(data => {
      if (!data.vehicles) return;

      data.vehicles.forEach(truck => {
        if (truck['latitude'] != null) {
          const date = new Date(truck['time']);
          const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
          + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());
    
          trucks.push(
            <Marker key={truck['vehicleId']}
                    position={[truck['latitude'], truck['longitude']]}
                    icon={new L.Icon({
                      iconUrl: 'truck.gif',
                      iconSize: [45, 36],
                      iconAnchor: [23, 36],
                      popupAnchor: [null, -36]
                    })}>
              <Popup autoPan={false}>
                <span>
                  {truck['vehicleId']}
                </span>
                <br/>
                <span>
                  Viimeisin aika: {time}
                </span>
              </Popup>
            </Marker>
          );
        }
      });

      this.setState({ trucks: trucks });
    }).catch(error => {
      console.log(error);
    });
  }

  changeDirection(value, event) {
    let directions = this.state.directions.slice();

    if (event.target.checked) {
      directions.push(value);
    }
    else {
      const index = directions.findIndex(direction => direction === value);
      directions.splice(index, 1);
    }

    this.setState({ directions: directions });
  }

  selectLine(index) {
    this.setState({
      selectedLine: index
    });
  }

  hoverLine(index) {
    this.setState({
      hoveredLine: index
    });
  }

  render() {
    return (
      <div>
        <div className='center'>
          {'Suunnat: '}
          <label>
            1
            <input id='direction1' type='checkbox' name='direction' value='1'
              onChange={this.changeDirection.bind(this, 1)}
              defaultChecked={this.state.directions.includes(1)}/>
          </label>
          <label>
            2
            <input id='direction2' type='checkbox' name='direction' value='2'
              onChange={this.changeDirection.bind(this, 2)}
              defaultChecked={this.state.directions.includes(2)}/>
          </label>
        </div>
        <div style={{ height: this.state.height }}>
          <link rel='stylesheet' href='https://unpkg.com/leaflet@1.4.0/dist/leaflet.css'
                integrity='sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=='
                crossOrigin='' />
          <Map ref={element => this.map = element} id='map-area' center={this.position} 
          zoom={this.zoom}  maxZoom={19}>
            <TileLayer url={this.state.mapTilesUrl}
                       attribution={this.state.mapTilesAttribution}
                       maxZoom={19} />
            { this.props.site != null && this.props.site.get('coating_plant_laditude') != null ? (
                <Marker position={[this.props.site.get('coating_plant_laditude'), this.props.site.get('coating_plant_longitude')]}
                        icon={new L.Icon({iconUrl: 'coating_plant.gif',
                              iconSize: [33, 50],
                              iconAnchor: [17, 50],
                              popupAnchor: [null, -50]
                              })}>
                  <Popup>
                    <span>{this.props.site.get('coating_plant_laditude')}, {this.props.site.get('coating_plant_longitude')}</span>
                  </Popup>
                </Marker>
              ) : null }
            { this.props.yourLaditude != null ? (
                <Marker position={[this.props.yourLaditude, this.props.yourLongitude]}
                        icon={new L.Icon({iconUrl: 'your_location.gif',
                              iconSize: [18, 43],
                              iconAnchor: [9, 43],
                              popupAnchor: [null, -43]
                              })}>
                  <Popup>
                    <span>{this.props.yourLaditude}, {this.props.yourLongitude}</span>
                  </Popup>
                </Marker>
              ) : null }
            {this.trucks}
            {this.state.lines}
          </Map>
        </div>
        {this.state.showedWarning ? 'Yhtä tai useampaa kuormaa ei voida näyttää sijainti tiedon virheellisyyden vuoksi.' : ''}
      </div>
    );
  }
};