import { fromJS } from 'immutable';

const initialState = fromJS({
  earthworks: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_EARTHWORKS': {
      return state.set('earthworks', fromJS([]));
    }

    case 'REMOVE_EARTHWORK': {
      const removableEarthwork = state.get('earthworks').findIndex(earthwork => earthwork.get('id') === fromJS(action.earthwork));
      if (removableEarthwork !== -1) {
        return state.set('earthworks', state.get('earthworks').delete(removableEarthwork));
      }
      return state;
    }

    case 'ADD_EARTHWORKS': {
      const sortedEarthworks = fromJS(action.earthworks).sort((a, b) => {
        return new Date(a.get('date')) - new Date(b.get('date'));
      });
      return state.set('earthworks', sortedEarthworks);
    }

    case 'ADD_EARTHWORK': {
      const newEarthwork = fromJS(action.earthwork);
       if (state.get('earthworks').find(earthwork => earthwork.get('id') === newEarthwork.get('id')) === undefined) {
         return state.set('earthworks', state.get('earthworks').push(newEarthwork));
       }
       return state;
     }

    case 'CHANGE_EARTHWORK': {
      const changingIndex = state.get('earthworks').findIndex(earthwork => earthwork.get('id') === fromJS(action.earthwork.id));

      if (changingIndex === -1) return state;

      if (state.get('earthworks').get(changingIndex) === fromJS(action.earthwork)) {
        return state;
      }
      const newEarthworks = state.get('earthworks').set(changingIndex, fromJS(action.earthwork));
      return state.set('earthworks', newEarthworks);
    }

    default: {
      return state;
    }
  }
};
